<template>
  <div class="info">
    <top-bar :title="!submitData.id?'新增文档':edit?'修改文档':'查看文档'" :left="true"></top-bar>
    <!-- 文档类型 -->
    <van-popup v-model="addrShow" position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="selectData.addrList" value-key="label"
                  @confirm="addrConfirm" @cancel="addrShow = false"/>
    </van-popup>
    <!-- 谁可以看 -->
    <van-popup v-model="userManagerTreeShow" position="bottom" >
      <van-cascader v-model="submitData.userManagerTree" title="请选择" :options="selectData.userManagerTreeList"
                    @close="userManagerTreeShow = false" @finish="userManagerTreeFinish" :field-names="fieldNames"/>
    </van-popup>

    <van-cell-group>
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">文档信息</span>
        </template>
      </van-cell>
      <van-cell title="文档名称">
        <van-field v-model="submitData.address" placeholder="请输入" :readonly="!edit"/>
      </van-cell>
      <van-cell title="文档类型" :value="selectMsg.docTypeStr" @click="addrShow = edit" :clickable="edit"
                :value-class="{'value-common':selectMsg.docTypeStr=='请选择'}" :is-link="edit" />
      <van-cell title="谁可以看" :value="selectMsg.userManagerTreeStr" @click="userManagerTreeShow = edit"
                :value-class="{'value-common':selectMsg.userManagerTreeStr=='请选择'}" :is-link="edit" />
      <van-field v-model="submitData.description" rows="3" class="left" autosize type="textarea" placeholder="请输入文档备注" :readonly="!edit"/>
      <van-row v-if="edit||fileList.length!==0">
        <van-col :span="24">
          <div class="upload">
            <upload-file
                :file-list="fileList"
                :del-btn-show="edit"
                :upload-btn-show="edit"
                @filesUpload="filesUpload"
                @delFile="delFile"
                :upload-path="uploadPath"
                :file-type="'all'"/>
          </div>
        </van-col>
      </van-row>
    </van-cell-group>

    <van-row class="btns">
      <van-col :span="24" v-if="edit">
        <van-button type="info" size="large" :loading="loading" round @click="submit">完成</van-button>
      </van-col>
      <van-row gutter="10" v-else>
        <van-col :span="12">
          <van-button plain type="danger" size="large" round @click="deleteHandle" style="background-color:transparent">删除</van-button>
        </van-col>
        <van-col :span="12">
          <van-button type="info" size="large" round @click="edit=true">编辑</van-button>
        </van-col>
      </van-row>
    </van-row>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import uploadFile from '@/components/upload/uploadFile'
import {beforeFileUpload, formatterDateTimeWithOutSec} from "@/utils/utils"
import {getImageStream} from '@/utils/index'

export default {
  data() {
    return {
      i: '',
      edit: false,
      file: {},
      detailData: {},
      uploadPath: '',
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      loading: false,
      addrShow: false,
      buildingTypeShow: false,
      buildingShow: false,
      userManagerTreeShow: false,
      inspectionTimeShow: false,
      selectMsg: {
        docTypeStr: '请选择',
        userManagerTreeStr: '请选择'
      },
      submitData: {
        id: '',
        docType: '',
        addrId: '',
        buildingType: '',
        rentHouseCount: 0,
        buildingId: '',
        subarea: '',
        address: '',
        detailedAddressValue: [],
        userManagerTree: '',
        inspectionTime: '',
        description: '',
        building: '',
        problemList: [],
        newFileMessages: [],
        deleteFiles: []
      },
      selectData: {
        addrList: [{label: "楼幢", value: "1"}, {label: "场所", value: "2"}, {label: "公共区域", value: "3"}],
        userManagerTreeList: [],
        problemList: []
      },
      nameList: [],
      valueList: [],
      fileList: [],
    }
  },
  components: {
    topBar,
    uploadFile
  },
  methods: {
    filesUpload (files) {
      this.submitData.newFileMessages = files
    },
    delFile (id) {
      this.submitData.deleteFiles.push(id)
    },
    // 初始化
    init() {
      this.getManagerTree()
      this.getDictTypeList('resourceType')
      if (this.submitData.id) {
        this.edit = false
        this.getInfo()
      } else {
        this.edit = true
      }

    },
    // 获取谁可以看
    getManagerTree() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/orgUserManagerTree'),
        method: 'get',
        params: this.$http.adornParams({
          orgId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          data.userManagerTree.forEach(item => {
            item.children.unshift({ label: "所有", value: 'all', id: item.value })
          })
          data.userManagerTree.unshift({ label: "重置", value: 'clear' })
          data.userManagerTree.unshift({ label: "所有", value: 'all', id: 0 })
          this.selectData.userManagerTreeList = data.userManagerTree
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取字典
    getDictTypeList(code) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          code: code,
          orgId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
            this.selectData.addrList = data.dicts
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取详情
    getInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/resource/info/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.submitData.id
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.submitData.docType = data.resourceInfoDisplayVO.dictIds[0]
          this.submitData.address = data.resourceInfoDisplayVO.name
          this.selectMsg.docTypeStr = data.resourceInfoDisplayVO.typeStr
          this.selectMsg.userManagerTreeStr = data.resourceInfoDisplayVO.rightUserName
          this.valueList = data.resourceInfoDisplayVO.rightUser.split(',')
          this.submitData.description = data.resourceInfoDisplayVO.remark
          this.fileList = data.resourceInfoDisplayVO.fileDisplayVOs
          this.submitData.newFileMessages = JSON.parse(JSON.stringify(this.fileList))
          this.fileList.forEach(item => {
            item['relativePath'] = item.relativeUrl
          })
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    deleteHandle() {
      this.$dialog.confirm({
        title: '确定删除吗？'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/wxapp/resource/info/delete'),
          method: 'post',
          params: this.$http.adornParams({
            ids: this.submitData.id
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$toast.success({
              message: '删除成功',
              duration: 3000,
              onOpened: () => {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 1500)
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    // 提交
    submit() {
      if (this.submitData.address === '') {
        return this.$toast.fail('失败:文档名称不能为空');
      }
      if (this.submitData.docType === '') {
        return this.$toast.fail('失败:文档类型不能为空');
      }
      if (this.valueList.length < 1) {
        return this.$toast.fail('失败:请选择谁可以看');
      }
      if (this.submitData.description === '') {
        return this.$toast.fail('失败:备注不能为空');
      }
      if (this.submitData.newFileMessages.length < 1) {
        return this.$toast.fail('失败:请先上传文件');
      }
      this.loading = true
      console.log(this.submitData.userManagerTree)
      this.$http({
        url: this.$http.adornUrl(`/wxapp/resource/info/${this.submitData.id ? 'update' : 'save'}`),
        method: 'post',
        data: {
          community: parseInt(this.submitData.orgId),
          deleteFiles: this.submitData.deleteFiles,
          fileMessages: this.submitData.newFileMessages.filter(item => !item.id),
          files: this.submitData.newFileMessages.filter(item => item.id),
          id: this.submitData.id ? parseInt(this.submitData.id) : 0,
          name: this.submitData.address,
          remark: this.submitData.description,
          rightUser: this.valueList.join(','),
          type: this.submitData.docType
        }
      }).then(({data}) => {
        if (data.code == 0) {
          this.$toast.success({
            message: `${this.submitData.id ? '修改成功' : '新增成功'}`,
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.loading = false
          this.$toast.fail(data.msg);
        }
      })
      this.loading = false
    },
    // 文档类型选择
    addrConfirm(value) {
      if (value) {
        this.selectMsg.docTypeStr = value.label
        this.submitData.docType = value.value
      }
      this.addrShow = false
    },
    // 谁可以看选择
    userManagerTreeFinish(value) {
      if (value) {
        if (value.value === 'all') {
          let valueList
          let nameList
          if (value.selectedOptions[value.selectedOptions.length - 1].id == 0) {
            valueList = this.getIds(this.selectData.userManagerTreeList, 'value', true)
            nameList = this.getIds(this.selectData.userManagerTreeList, 'label', true)
          } else {
            this.selectData.userManagerTreeList.forEach(item => {
              if (value.selectedOptions[value.selectedOptions.length - 1].id === item.value) {
                valueList = this.getIds(item.children, 'value', false)
                nameList = this.getIds(item.children, 'label', false)
              }
            })
          }
          this.valueList = new Array(...new Set(this.valueList.concat(valueList)))
          this.nameList = new Array(...new Set(this.nameList.concat(nameList)))
          this.selectMsg.userManagerTreeStr = this.nameList.join(',')
          // this.submitData.userManagerTree = new Array(this.valueList)
        } else if (value.value === 'clear') {
          this.selectMsg.userManagerTreeStr = ''
          this.submitData.userManagerTree = ''
          this.valueList = []
          this.nameList = []
        } else {
          console.log(value)
          this.valueList = new Array(...new Set(this.valueList.concat([value.value])))
          this.nameList = new Array(...new Set(this.nameList.concat([value.selectedOptions[value.selectedOptions.length - 1].label])))
          this.selectMsg.userManagerTreeStr = this.nameList.join(',')
        }
      }
      // this.userManagerTreeShow = false
    },
    getIds(list, name, isChild) {
      let arr = []
      list.forEach(item => {
        if (isChild && item.value !== 'all' && item.value !== 'clear') {
          item.children.forEach(val => {
            if (val.value !== 'all') {
              arr.push(val[name])
            }
          })
        } else {
          if (!isChild && item.value !== 'all') {
            arr.push(item[name])
          }
        }
      })
      return arr
    },
  },
  created() {
    this.uploadPath = "files/resource/"+ this.$orgId +"/temporary"
    this.submitData.id = this.$route.query.id || ''
    document.querySelector('html').style.backgroundColor = '#fff'
    this.submitData.orgId = this.$orgId
    this.submitData.userId = this.$globalData.userInfo.userId
    this.init()
  },
  beforeDestroy() {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
<style scoped lang="scss">
.upload {
  padding-left: 30px;
}
</style>
